<template>
  <div class="era-postion-container">
    <div class="era-postion-head">
      <p>开始计算</p>
      <p>开始模拟</p>
      <p>开始探索</p>
    </div>
    <div class="era-postion-title">岗位详情</div>
    <div class="era-postion-content">
      <div class="postion-name">岗位名称：</div>
      <div class="postion-loction">地点：</div>
      <div class="postion-describe">描述：</div>
      <div class="postion-ask">要求：</div>
      <div class="postion-treatment">待遇</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Position",
  data() {
    return {
      /**
       * 点击开放岗位
       */
      onLearnmore() {
        this.$router.push("/position");
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.era-postion-container {
  .era-postion-head {
    background: url("../assets/images/openpositions-01.png") no-repeat center
      0px;
    background-position: center 0px;
    background-size: cover;
    width: 100%;
    height: 25vw;
    display: flex;
    p {
      color: rgb(255, 255, 255);
      font-family: 思源黑体 CN;
      font-size: 3.6vw;
      font-weight: 550;
      line-height: 120px;
      letter-spacing: 0.3vw;
      padding: 7.2vw 8.6vw;
    }
  }
  .era-postion-title {
    font-size: 2.6vw;
    font-weight: bold;
    color: #000000;
    padding: 3vw 42vw;
  }
}
</style>
